import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private googleAnalyticsSubject = new BehaviorSubject({});
  googleAnalytics$ = this.googleAnalyticsSubject.asObservable();
  constructor() { }

  /**
   * Get google analytics data
   * @param analyticsData 
   */
  getGoogleAnalytics(analyticsData: any) {
    this.googleAnalyticsSubject.next(analyticsData);
  }
}

